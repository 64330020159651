import { ProductExtraGuaranteeState } from '~/types/vuex/ProductExtraGuarantee';
import { NavigationState } from '~/types/vuex/Navigation';
import { ClientIPState } from '~/types/vuex/ClientIP';
import { ClientEmailState } from '~/types/vuex/ClientEmail';
import { AnchorNavState } from '~/types/vuex/AnchorNav';
import { StockState } from '~/types/vuex/Stock';
import { CartTrackingState } from '~/types/vuex/CartTracking';
import { StoreVsfExtendedState } from '~/types/vuex/StoreVsfExtended';

export const MODULE_NAMES = {
  EXTRA_GUARANTEE_MODULE_NAME: 'productExtraGuarantee',
  NAVIGATION_MODULE_NAME: 'navigation',
  CLIENT_IP_MODULE_NAME: 'clientIP',
  CLIENT_EMAIL_MODULE_NAME: 'clientEmail',
  ANCHOR_NAV_MODULE_NAME: 'anchorNav',
  STOCK_MODULE_NAME: 'stock',
  CART_TRACKING_MODULE_NAME: 'cartTracking',
  STORE_VSF_EXTENDED_MODULE_NAME: 'storeVsfExtended'
} as const;

export type RootState = {
  [MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME]: ProductExtraGuaranteeState
  [MODULE_NAMES.NAVIGATION_MODULE_NAME]: NavigationState,
  [MODULE_NAMES.CLIENT_IP_MODULE_NAME]: ClientIPState,
  [MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME]: ClientEmailState,
  [MODULE_NAMES.ANCHOR_NAV_MODULE_NAME]: AnchorNavState,
  [MODULE_NAMES.STOCK_MODULE_NAME]: StockState,
  [MODULE_NAMES.CART_TRACKING_MODULE_NAME]: CartTrackingState,
  [MODULE_NAMES.STORE_VSF_EXTENDED_MODULE_NAME]: StoreVsfExtendedState,
};

export interface ExtendedState {
  getterKeys?: string[],
  mutationKeys?: string[],
  actionKeys?: string[]
}
