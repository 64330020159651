
import { EVENT_PAGE_TYPE } from '~/constants/googleTagManager';
import { useIsOnCategory, useIsPage } from '~/composables';
import { PageViewPageCategory } from '~/types/integrations/tagManager/TagManagerIntegration';

export default function (): { getCurrentPageType: Function } {
  const { isOnProductPage, isOnHomePage, isCheckoutPage, isOnSearchPage } = useIsPage();
  const { isOnCategory } = useIsOnCategory();

  const getCurrentPageType = (): PageViewPageCategory => {
    let pageType: PageViewPageCategory;
    const isCategory = isOnCategory();
    if (isOnSearchPage.value) {
      pageType = EVENT_PAGE_TYPE.SEARCH;
    } else if (isOnHomePage.value) {
      pageType = EVENT_PAGE_TYPE.HOMEPAGE;
    } else if (isOnProductPage.value) {
      pageType = EVENT_PAGE_TYPE.PDP;
    } else if (isCheckoutPage.value) {
      pageType = EVENT_PAGE_TYPE.CHECKOUT;
    } else if (isCategory) {
      pageType = EVENT_PAGE_TYPE.CATEGORY;
    }
    return pageType;
  };

  return { getCurrentPageType };
}
