import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { StockState, StockMutations } from '~/types/vuex/Stock';
const state = (): StockState => ({
  result: null,
  loading: false,
  error: {
    search: null
  }
});

const mutations: StockMutations = {
  setStock(state, payload) {
    state.result = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error.search = payload;
  }
};

export default extendModule({
  state,
  mutations
});
