import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { StoreVsfExtendedState } from '~/types/vuex/StoreVsfExtended';

const state = (): StoreVsfExtendedState => ({
  loading: true,
  customFieldsRaw: []
});
const mutations = {
  setLoading(state: StoreVsfExtendedState, payload: StoreVsfExtendedState['loading']) {
    state.loading = payload;
  },
  setCustomFieldsRaw(state: StoreVsfExtendedState, payload: StoreVsfExtendedState['customFieldsRaw']) {
    state.customFieldsRaw = payload;
  }
};

export default extendModule({
  state,
  mutations
});
