import { useVSFContext, Logger } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import type { InventoryEntry, LineItem, Cart } from '@vsf-enterprise/commercetools-types';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { StockMutations } from '~/types/vuex/Stock';
type SkuType = InventoryEntry['sku'];

/*
 * Custom composable for fetching product stock from commercetools Inventory endpoint.
 */
export const useStock = () => {
  const store = defineModule<typeof MODULE_NAMES.STOCK_MODULE_NAME,
  StockMutations,
  {},
  {}>(MODULE_NAMES.STOCK_MODULE_NAME);
  const context = useVSFContext();

  const result = computed(() => store.state.result);
  const loading = computed(() => store.state.loading);
  const error = computed(() => store.state.error);

  const getStocks = async (params: { sku: SkuType | SkuType[], cartId?: Cart['id'] }) => {
    Logger.debug('inventory', params);

    try {
      store.mutations.setLoading(true);
      if (Array.isArray(params.sku) && params.sku.length === 0) {
        store.mutations.setStock([]);
      } else {
        const apiCallResult = await context.$ct.api.getInventory(params);
        store.mutations.setStock(apiCallResult?.data?.inventoryEntries?.results || []);
      }
      store.mutations.setError(null);
    } catch (err) {
      store.mutations.setError(err ? String(err) : null);
      Logger.error('inventory', err);
    } finally {
      store.mutations.setLoading(false);
    }
  };

  const findStockBySku = (sku: SkuType): InventoryEntry | undefined => {
    return result.value?.find((stock) =>
      stock.sku === sku
    );
  };

  const findProductStockBySku = (sku: string): InventoryEntry['availableQuantity'] | 0 => {
    const stock = findStockBySku(sku);
    return stock?.availableQuantity || 0;
  };

  const loadStock = async ({
    items,
    cartId
  }: { items: LineItem[], cartId?: Cart['id'] }) => {
    if (!items || items.length === 0) return;
    const skuList: string[] = items?.map((item: LineItem) => item?.variant?.sku ?? '').filter(sku => sku !== '');
    if (skuList.length) {
      await getStocks({
        sku: skuList,
        cartId
      });
    }
  };

  return {
    getStocks,
    stocks: result,
    findStockBySku,
    findProductStockBySku,
    loadStock,
    loading,
    error,
    ...store.mutations
  };
};
