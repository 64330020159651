import { onSSR } from '@vue-storefront/core';
import { cartGetters, useShipping, useBilling } from '@vsf-enterprise/commercetools';
import { onMounted, watch, computed } from '@nuxtjs/composition-api';
import {
  useStock,
  useCartExtended,
  useLineItems,
  useStoreVsfExtended,
  useUserExtended,
  useVolumeDiscounts,
  useProductExtraGuarantee,
  useCartWebSocket
} from '~/composables';

export default function () {
  const { cart, load: loadCart, setCart } = useCartExtended();
  const { load: loadStore, isLoaded } = useStoreVsfExtended();
  const { load: loadShipping, shipping } = useShipping();
  const { load: loadBilling, billing } = useBilling();
  const { load: loadVolumeDiscounts } = useVolumeDiscounts();
  const {
    productExtraGuarantee: extraGuaranteeProduct,
    load: loadExtraGuaranteeProduct
  } = useProductExtraGuarantee();

  const { getStocks, stocks } = useStock();
  const { lineItems } = useLineItems();
  const { user, load: loadUser } = useUserExtended();
  const { openWebSocket } = useCartWebSocket(setCart);

  onMounted(() => {
    if (!cart?.value) {
      loadCart();
    }
    if (!user?.value) {
      loadUser();
    }
    if (!isLoaded.value) {
      loadStore();
    }
    if (!extraGuaranteeProduct.value) {
      loadExtraGuaranteeProduct();
    }
  });

  onSSR(async () => {
    await loadVolumeDiscounts();
  });

  const cartId = computed(() => cart?.value?.id);

  const sku = computed(() => lineItems.value.map(product => cartGetters.getItemSku(product)));

  watch(cartId, (value) => {
    if (value) {
      openWebSocket(value);
      if (!stocks.value) {
        getStocks({
          sku: sku.value
        });
      }
      if (!shipping.value) {
        loadShipping();
      }
      if (!billing.value) {
        loadBilling();
      }
    }
  });

  return {
    lineItems
  };
}
