import { productGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { HitResultItem } from 'vue-instantsearch';
import { TagProduct, TagProductCategories } from '~/types/integrations/tagManager/TagProduct';
import { TagPromotion } from '~/types/integrations/tagManager/TagPromotion';
import { RemarketingTagParams } from '~/types/integrations/tagManager/RemarketingTagParams';
import { RemarketingPageType } from '~/types/integrations/tagManager/RemarketingPageType';
import { centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { SHIPPING_TYPE } from '~/constants/shippingTypes';
import { ITEM_ACCESSORY, ITEM_PRODUCT } from '~/constants/productType';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { getProductPriceAfterDiscounts } from '~/helpers/product/getPriceInfo';
import { calculateHitResultProductDiscount } from '~/helpers/algoliaHitProduct/calculateDiscount';
import { ViewAccessoryItems } from '~/types/integrations/tagManager/TagViewAccessory';
import { ITEM_CATEGORY } from '~/constants/googleTagManager';
import type { ProductCategories } from '~/types/product/ProductCategories';
import { CATEGORY_FILTER_INDICATOR } from '~/constants/algolia';
import { TagProductBox, ProductBoxData } from '~/types/integrations/tagManager/TagProductBox';

// TODO - TRAN-526 - extend ProductVariant type with breadcrumbs etc
type ProductVariantWithBreadcrumbs = ProductVariant & {
  _breadcrumbs: any
};

const createDefaultCategories = (): TagProductCategories => ({
  item_category: '',
  item_category2: ''
});

const extractProductCategory = (product: ProductVariant, level: number): string => {
  const productWithBreadcrumbs = product as ProductVariantWithBreadcrumbs;
  const breadcrumbs = productWithBreadcrumbs?._breadcrumbs?.[0];
  return breadcrumbs?.length ? breadcrumbs[breadcrumbs.length - level]?.name : '';
};

const setProductCategories = (product: ProductVariant): TagProductCategories => {
  const productWithBreadcrumbs = product as ProductVariantWithBreadcrumbs;
  const productCategoriesLenght = productWithBreadcrumbs?._breadcrumbs?.[0]?.length || 0;
  const categories: TagProductCategories = {
    item_category: '',
    item_category2: ''
  };
  for (let i = 1; i <= productCategoriesLenght; i++) {
    const key = i === 1 ? ITEM_CATEGORY : `${ITEM_CATEGORY}${i}` as keyof TagProductCategories;
    categories[key] = extractProductCategory(product, productCategoriesLenght - i);
  }
  return categories;
};

export const isValidCategoryKey = (key: string): key is keyof TagProductCategories => {
  return key.startsWith(ITEM_CATEGORY);
};

export const generateCategoryKey = (index: number): string => {
  return `${ITEM_CATEGORY}${index === 0 ? '' : index + 1}`;
};

export const handleStringCategories = (
  productCategories: string
): TagProductCategories => {
  const categories = createDefaultCategories();
  const categoryArray = productCategories.split(CATEGORY_FILTER_INDICATOR).map(category => category.trim());

  categoryArray.forEach((category, index) => {
    const key = generateCategoryKey(index);
    if (isValidCategoryKey(key)) {
      categories[key] = category;
    }
  });

  return categories;
};

export const handleObjectCategories = (
  productCategories: Record<string, string[]>
): TagProductCategories => {
  const categories = createDefaultCategories();
  Object.values(productCategories).forEach((category, index) => {
    if (!category?.length) return;

    const categoryLevel = category[0];
    let categoryValue: string = categoryLevel?.trim() || '';

    if (index > 0) {
      const lastFilterIndicatorIndex = categoryValue.lastIndexOf(CATEGORY_FILTER_INDICATOR);
      if (lastFilterIndicatorIndex !== -1) {
        categoryValue = categoryValue.substring(lastFilterIndicatorIndex + CATEGORY_FILTER_INDICATOR.length).trim();
      }
    }

    const key = generateCategoryKey(index);
    if (isValidCategoryKey(key)) {
      categories[key] = categoryValue;
    }
  });

  return categories;
};

export const prepareProductTags = (
  product: ProductVariant,
  details: {
    currency?: string,
    quantity?: number,
    languageAndCountry?: string,
    inStock?: boolean,
    index?: number
  }
): TagProduct => {
  const standardPriceJson = getProductAttribute(product, PRODUCT_ATTRIBUTES.STANDARD_PRICE, details.languageAndCountry);
  const standardPriceDetails = standardPriceJson ? JSON.parse(standardPriceJson) : null;
  const price = getProductPriceAfterDiscounts(product);
  const originalPrice = centAmountToNormalPrice(standardPriceDetails?.centAmount, price);
  const discount = originalPrice && originalPrice > price ? originalPrice - price : 0;
  const isFreight = getProductAttribute(
    product, PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT, details.languageAndCountry);
  const hasParent = getProductAttribute(
    product, PRODUCT_ATTRIBUTES.PARENT_PRODUCTS_FOR_ACCESSORY);

  const productTags: TagProduct = {
    ...setProductCategories(product),
    item_name: productGetters.getName(product),
    price,
    item_id: productGetters.getSku(product),
    item_brand: getProductAttribute(product, PRODUCT_ATTRIBUTES.BRAND, details.languageAndCountry),
    item_variant: productGetters.getAttributes(product, [PRODUCT_ATTRIBUTES.MODEL])?.model,
    currency: details.currency,
    discount,
    quantity: details.quantity || 1,
    in_stock: details.inStock,
    shipping_type: isFreight ? SHIPPING_TYPE.FREIGHT : SHIPPING_TYPE.PARCEL,
    item_type: hasParent ? ITEM_ACCESSORY : ITEM_PRODUCT,
    index: details.index
  };

  if (details.languageAndCountry) {
    productTags.languageAndCountry = details.languageAndCountry;
  }

  return productTags;
};

export const prepareSimilarProductTags = (
  similarProducts: ProductVariant[], languageAndCountry: string): TagProduct[] => {
  const preparedSimilarProducts = [];
  similarProducts.map((product, index) =>
    preparedSimilarProducts.push({
      brand: getProductAttribute(product, PRODUCT_ATTRIBUTES.BRAND, languageAndCountry),
      name: productGetters.getName(product),
      price: getProductPriceAfterDiscounts(product),
      id: productGetters.getSku(product),
      list: 'Similar Articles',
      position: index + 1
    })
  );
  return preparedSimilarProducts;
};

export const preparePromotionTags = (promotionImageName: string, promotionImagePosition: number): TagPromotion[] => {
  const staticPromotionId = 'HP_Slider';
  const staticPromotionName = 'HP Slider';
  return [
    {
      creative: promotionImageName,
      id: staticPromotionId,
      name: staticPromotionName,
      position: promotionImagePosition
    }
  ];
};

export const prepareRemarketingTags = (
  pageType: RemarketingPageType,
  product: ProductVariant
): RemarketingTagParams => {
  return {
    ecomm_pagetype: pageType,
    ecomm_pcat: [extractProductCategory(product, 1)],
    ecomm_pname: productGetters.getName(product),
    ecomm_prodid: productGetters.getSku(product),
    ecomm_pvalue: getProductPriceAfterDiscounts(product),
    ecomm_totalvalue: getProductPriceAfterDiscounts(product)
  };
};

export const extractProductCategories = (
  productCategories: ProductCategories
): TagProductCategories => {
  return typeof productCategories === 'string'
    ? handleStringCategories(productCategories)
    : handleObjectCategories(productCategories);
};

export const prepareViewItemListTags = (
  products: HitResultItem[],
  languageAndCountry: string
): TagProduct[] => {
  const preparedProducts: TagProduct[] = [];
  products.forEach(product => {
    const discount = calculateHitResultProductDiscount(product);

    return preparedProducts.push({
      ...extractProductCategories(product.categories || {}),
      item_brand: product.brand || '',
      currency: product.priceCurrency,
      item_id: product.objectID,
      languageAndCountry,
      item_name: product.name || '',
      discount,
      price: product.price || 0,
      quantity: product.stockCount || 0,
      in_stock: product.inStock
    });
  });
  return preparedProducts;
};

export const prepareViewAccessoryItemsTags = (
  accessoryProducts: ProductVariant[]
): ViewAccessoryItems => {
  let accessoryIds: string[] = [];
  accessoryIds = accessoryProducts.reduce((filtered, accessoryProduct) => {
    if (accessoryProduct.sku) {
      filtered.push(accessoryProduct.sku);
    }

    return filtered;
  }, accessoryIds);
  return {
    accessory_count: accessoryIds.length,
    accessory_ids: accessoryIds
  };
};

export const prepareClickProductBoxTags = (
  product: ProductBoxData
): TagProductBox => {
  return {
    item_id: product.id,
    item_name: product.name,
    currency: product.currency,
    discount: product.discountedPrice ? +(product.price - product.discountedPrice).toFixed(2) : 0,
    item_brand: product.brand,
    price: product.discountedPrice ? product.discountedPrice : product.price,
    ...extractProductCategories(product.categories)
  };
};
