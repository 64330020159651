import { useCategory } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { CATEGORY_TREE } from '~/constants/categoryTree';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { sortCategories } from '~/helpers/category/sortCategories';

export const useCategoryExtended = () => {
  const {
    categories: firstLevelCategoriesContainerRaw,
    search: searchFirstLevelCategories
  } = useCategory('navigation-first-level-categories');

  const loadFirstLevelCategories = async (languagePrefix = '') => {
    await searchFirstLevelCategories({
      key: CATEGORY_TREE.DEFAULT.key,
      languagePrefix,
      customQuery: { categories: CUSTOM_QUERIES.CATEGORIES.NAVIGATION }
    });
  };

  const firstLevelCategoriesContainer = computed(() =>
    firstLevelCategoriesContainerRaw.value?.map((rootCategory) => ({
      ...rootCategory,
      children: sortCategories(rootCategory.children ?? [])
    })) ?? []
  );

  return {
    loadFirstLevelCategories,
    firstLevelCategoriesContainer
  };
};
