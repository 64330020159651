import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { CustomQuery } from '@vue-storefront/core';
import type {
  Address,
  Payment,
  CartUpdateAction,
  ProductVariant,
  LineItem,
  Cart,
  Customer
} from '@vsf-enterprise/commercetools-types';
import { useCart, useUser, userGetters } from '@vsf-enterprise/commercetools';
import type { Ref } from '@nuxtjs/composition-api';
import { computed, reactive, ref } from '@nuxtjs/composition-api';
import {
  setCustomerEmail,
  setShippingAddressAction,
  setBillingAddressAction,
  addPayment,
  addExtraGuaranteeLineItem
} from '~/helpers/cart/cartActions';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import {
  useI18n,
  useIntegrations,
  useStock
} from '~/composables';
import {
  setVatNumber,
  VatNumber
} from '~/helpers/cart/customFields';
import { CartUpdateAction as CiaUpdateAction } from '~/types/integrations/cia/event/cart-update/CartUpdateAction';
import { orderWithoutEmailId } from '~/constants/log';
import { VatResult } from '~/types/integrations/vatValidation/VatIntegration';
import callFunctionWithRetries from '~/helpers/retry/callFunctionWithRetries';
import { getStoreKey } from '~/helpers/cart/getStoreKey';
import { asyncDebounce } from '~/helpers/lodash/asyncDebounce';
import { usePromiseQueue } from '~/composables/modules/usePromiseQueue';
import {
  EVENT_SOURCE
} from '~/constants/googleTagManager';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';

export const ERROR_NOT_FOUND_IN_STORE = 'was not found in store';

const customError = reactive(
  { updateCustomCart: '' }
);

export default function () {
  const {
    cart,
    setCart: oldSetCart,
    load: oldLoad,
    addItem: oldAddItem,
    removeItem: oldRemoveItem,
    updateItemQty: oldUpdateItemQty,
    clear: oldClear,
    applyCoupon: oldApplyCoupon,
    removeCoupon: oldRemoveCoupon,
    loading,
    error: oldError
  } = useCart();

  const {
    loadStock
  } = useStock();

  const isLoaded = ref(false);

  const setCart = oldSetCart as (cart: Cart | null) => void;

  const { countryLocalization, countryCode } = useI18n();
  const { $ct: { api } } = useVSFContext();
  const { $cia, $bloomreach } = useIntegrations();
  const CART_PROMISE_QUEUE_KEY = 'cartPromiseQueue';

  const oldAddItemQueued = usePromiseQueue(oldAddItem, CART_PROMISE_QUEUE_KEY).execute;
  const oldRemoveItemQueued = usePromiseQueue(oldRemoveItem, CART_PROMISE_QUEUE_KEY).execute;
  const oldUpdateItemQtyQueued = usePromiseQueue(oldUpdateItemQty, CART_PROMISE_QUEUE_KEY).execute;
  const oldClearQueued = usePromiseQueue(oldClear, CART_PROMISE_QUEUE_KEY).execute;
  const oldApplyCouponQueued = usePromiseQueue(oldApplyCoupon, CART_PROMISE_QUEUE_KEY).execute;
  const oldRemoveCouponQueued = usePromiseQueue(oldRemoveCoupon, CART_PROMISE_QUEUE_KEY).execute;

  const reloadCart = async () => {
    setCart(null);
    await load();
  };

  const error = computed(() => ({
    ...oldError.value,
    ...customError
  }));

  const { user } = useUser();
  // TODO - revert syntax once fix is pushed for type incoherence between composables and getters
  // https://expondo.atlassian.net/browse/INSP-988
  const userEmailAddress = computed(() => userGetters.getEmailAddress(user.value as Customer));
  const emailAddress = computed(() => cart.value?.customerEmail);

  const handleCartUpdate = ({ action, sku, recommendationId }:
    { action: CiaUpdateAction, sku: string, recommendationId?: string }) => {
    try {
      $cia.event.cartUpdate({ action, sku, recommendationId });
    } catch (error) {
      Logger.error(`cia|cartUpdate error: ${error}`);
    }
  };

  const persistCartId = (cartId?: string) => {
    if (cartId) {
      try {
        $cia.mutations.setCartId(cartId);
      } catch (error) {
        Logger.error(`cia|setCartId error: ${error}`);
      }
    }
  };

  const getRecommendationId = (triggeredIn?: string) => {
    return triggeredIn === EVENT_SOURCE.PDP_SIMILAR_PRODUCTS
      ? $bloomreach.state.bloomreachDetails.similarProductsId
      : undefined;
  };

  function getUpdateCustomerEmailAction(email: Maybe<string>): CartUpdateAction | undefined {
    if (!email) {
      return;
    }
    if (emailAddress.value === email) {
      return;
    }
    try {
      $cia.mutations.setEmail(email);
    } catch (error) {
      Logger.error(`cia|setEmail error: ${error}`);
    }
    return setCustomerEmail(email);
  }

  async function updateCustomerEmail(email: Maybe<string>) {
    if (!cart.value) {
      await load();
    }
    if (cart.value) {
      const action = getUpdateCustomerEmailAction(email);
      action && await updateCustomCart([action]);
    } else {
      Logger.info(`${orderWithoutEmailId} cart setEmail wasn't able to set because of cart not existed`);
    }
  }

  async function updatePayment(id: Payment['id']) {
    if (cart.value) {
      const actions: CartUpdateAction[] = [addPayment({ id })];
      await updateCustomCart(actions);
    }
  }

  async function addExtraGuaranteeProduct(parentSku: string) {
    const action: CartUpdateAction = addExtraGuaranteeLineItem(parentSku);
    await updateCustomCart([action]);

    handleCartUpdate({ action: CiaUpdateAction.Add, sku: parentSku });
  }

  const setUpdateCustomCartError = (value: string) => {
    customError.updateCustomCart = value;
  };
  const _updateCustomCartWithRetries = async (actions: CartUpdateAction[]) => {
    setUpdateCustomCartError('');
    const { error } = await callFunctionWithRetries(() => updateCustomCartFunction(actions));
    setUpdateCustomCartError(error);
  };
  const _updateCustomCartDebounced = asyncDebounce(async (actions: CartUpdateAction[]) => {
    actionsSaved.value = [];
    await promiseQueue.execute(actions);
  }, DEBOUNCE_TIMES.CART_API);
  const promiseQueue = usePromiseQueue(_updateCustomCartWithRetries, CART_PROMISE_QUEUE_KEY);
  const actionsSaved = sharedRef<CartUpdateAction[]>([], 'actionsCart');

  async function updateCustomCart(actions: CartUpdateAction[]) {
    actionsSaved.value.push(...actions);
    const currentActionsSaved = [...actionsSaved.value];
    await _updateCustomCartDebounced(currentActionsSaved);
  }
  const updateCustomCartFunction = async (actions: CartUpdateAction[]) => {
    if (!actions.length) {
      return;
    }
    const cartResponse = await api.updateCart({
      id: cart.value.id,
      version: cart.value.version,
      actions
    }, CUSTOM_QUERIES.UPDATE_CART_CUSTOM);
    const dataCart = cartResponse?.data?.cart;
    if (dataCart) {
      setCart(dataCart);
      return;
    }
    const message = cartResponse?.message;
    if (!message) {
      return;
    }
    Logger.error('UpdateCustomCart error, message: ' + message + ' actions: ' + JSON.stringify(actions));
    await handleErrorMessage(message);
    setUpdateCustomCartError(message);
    throw message;
  };

  async function setShippingDetails(address: Partial<Address>) {
    if (cart.value) {
      let actions: CartUpdateAction[] = [];
      actions = [
        setShippingAddressAction(address)
      ];
      await updateCustomCart(actions);
    }
  }

  async function updateCustomCartWithNecessary () {
    const actions: CartUpdateAction[] = [];
    if (!cart.value?.billingAddress?.country) {
      actions.unshift(setBillingAddressAction({ country: countryCode.value }));
    }
    if (!cart.value?.shippingAddress?.country) {
      actions.unshift(setShippingAddressAction({ country: countryCode.value }));
    }
    const emailAction = getUpdateCustomerEmailAction(userEmailAddress.value);
    emailAction && actions.unshift(emailAction);
    if (!actions.length) return;
    await updateCustomCart(actions);
  }

  async function setBillingDetails(
    address: Partial<Address>,
    vatNumber: VatNumber,
    vatResult: VatResult | null = null
  ) {
    if (!cart.value) {
      return;
    }

    const actions: CartUpdateAction[] = [
      setBillingAddressAction(address)
    ];
    if (vatNumber) {
      actions.push(setVatNumber('Billing', vatNumber, vatResult));
    }

    await updateCustomCart(actions);
  }

  async function load() {
    await oldLoad();
    isLoaded.value = true;
    if (!cart.value) {
      return;
    }
    await clearIfOtherStoreCart();
    if (!cart.value) {
      return;
    }
    persistCartId(cart.value.id);
    if (cart.value.lineItems) {
      loadStock({ items: cart.value.lineItems });
    }
  }

  async function clearIfOtherStoreCart() {
    const storeKey = getStoreKey(cart.value);
    if (storeKey && (storeKey !== countryLocalization?.value.storeId)) {
      await clear();
    }
  }

  async function addItem(params:
    { product: ProductVariant, quantity: number, customQuery?: CustomQuery, triggeredIn?: string }) {
    await load();
    const lineItem = cart.value?.lineItems?.find((item: LineItem) => item.variant?.sku === params.product.sku);
    if (lineItem) {
      const newQuantity = lineItem.quantity + params.quantity;
      await updateItemQty({
        product: lineItem,
        quantity: newQuantity,
        customQuery: params.customQuery
      }, CiaUpdateAction.Add);
      return;
    }
    await oldAddItemQueued(params);
    const message = error.value.addItem?.message;
    if (!message) {
      await updateCustomCartWithNecessary();
      const recommendationId = getRecommendationId(params.triggeredIn);
      handleCartUpdate({ action: CiaUpdateAction.Add, sku: params.product.sku, recommendationId });
      cart?.value?.lineItems && loadStock({ items: cart.value.lineItems, cartId: cart.value.id });
      return;
    }
    await handleErrorMessage(message);
  }

  async function removeItem(params: { product: LineItem, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await oldRemoveItemQueued(params);
      handleCartUpdate({ action: CiaUpdateAction.Remove, sku: params.product?.variant?.sku });
    }
  }

  async function updateItemQty(params: {
    product: LineItem,
    quantity?: number,
    customQuery?: CustomQuery
  }, ciaActionType = CiaUpdateAction.Remove) {
    if (!cart.value) {
      return;
    } // TODO - validate if this if makes sense for this check  INSP-776
    await oldUpdateItemQtyQueued(params);
    const message = error.value.updateItemQty?.message;
    if (!message) {
      handleCartUpdate({ action: ciaActionType, sku: params.product?.variant?.sku });
      return;
    }
    await handleErrorMessage(message);
  }

  async function handleErrorMessage(message: string) {
    if (message?.includes(ERROR_NOT_FOUND_IN_STORE)) {
      await clear();
      return;
    }
    await reloadCart();
    throw new Error(message);
  }

  async function clear() {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId('');
      await oldClearQueued();
      setCart(null);
    }
  }

  async function applyCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await oldApplyCouponQueued(params);
    }
  }

  async function removeCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    if (cart.value) { // TODO - validate if this if makes sense for this check  INSP-776
      persistCartId(cart.value.id);
      await oldRemoveCouponQueued(params);
    }
  }

  async function updateQuantity(product: LineItem, quantity: number) {
    await updateQuantityWithoutRetry(product, quantity);
    if (error.value.updateItemQty?.message) {
      await clear();
      await updateQuantityWithoutRetry(product, quantity);
    }
  }

  async function updateQuantityWithoutRetry(product: LineItem, quantity: number) {
    await oldUpdateItemQtyQueued({
      product,
      quantity,
      customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM
    });
  }

  const taxMode = computed(() => cart?.value?.taxMode);

  const calculatedCodFee = computed(() =>
    cart?.value?.custom?.customFieldsRaw?.find(field => field.name === 'calculatedCodFee')?.value);

  const codFeeAmount = computed(() =>
    calculatedCodFee.value ? calculatedCodFee.value.centAmount / (10 ** calculatedCodFee.value.fractionDigits) : 0);

  return {
    // TODO - revert syntax once fix is pushed for type incoherence between composables and getters -
    // https://expondo.atlassian.net/browse/INSP-988
    cart: cart as Ref<Cart>,
    codFeeAmount,
    loading,
    isLoaded: computed(() => (isLoaded.value || !!cart.value)),
    error,
    setCart,
    updateCustomerEmail,
    updatePayment,
    setShippingDetails,
    setBillingDetails,
    updateCustomCart,
    updateCustomCartWithNecessary,
    load,
    clearIfOtherStoreCart,
    addItem,
    removeItem,
    updateItemQty,
    clear,
    applyCoupon,
    removeCoupon,
    reloadCart,
    setUpdateCustomCartError,
    updateQuantity,
    taxMode,
    addExtraGuaranteeProduct
  };
}
