import { Logger } from '@vue-storefront/core';
import { useWebSocket } from '@vueuse/core';
import { webSocketPort } from '~/config/shared';
import { WEBSOCKET_CONFIG, WEBSOCKET_EVENT } from '~/constants/webSocket';
import { useStock, useI18n } from '~/composables';
import { isWebSocketEnabledForCountry } from '~/helpers/locales/isWebSocketEnabledForCountry';

export const useCartWebSocket = (setCart: Function) => {
  const { setStock } = useStock();
  const { countryCode } = useI18n();

  const isDev = process.env.NODE_ENV === 'development';

  function constructWebSocketUrl(cartId: string) {
    const webSocketProdHost = `${WEBSOCKET_CONFIG.WSS_PROTOCOL}${window.location.host}`;
    const host = isDev ? WEBSOCKET_CONFIG.DEV_HOST : webSocketProdHost;
    return `${host}:${webSocketPort}?${WEBSOCKET_CONFIG.CART_ID}=${cartId}`;
  }

  function openWebSocket(cartId: string) {
    if (!isWebSocketEnabledForCountry(countryCode.value)) {
      return;
    }

    const socketUrl = constructWebSocketUrl(cartId);
    useWebSocket(socketUrl, {
      autoReconnect: handleAutoReconnect,
      onMessage(_ev, message) {
        handleWebSocketMessage(message);
      },
      onError: (error) => Logger.error('WebSocket Error:', error)
    });
  }

  const handleAutoReconnect = {
    retries: WEBSOCKET_CONFIG.RETRIES,
    delay: WEBSOCKET_CONFIG.DELAY,
    onFailed() {
      Logger.error('WebSocket error: Failed to connect after 3 retries');
    }
  };

  function handleWebSocketMessage(message: MessageEvent) {
    const parsedData = JSON.parse(message.data);
    const event = parsedData.event;

    switch (event) {
      case WEBSOCKET_EVENT.CART_UPDATE: {
        const cart = parsedData?.data?.cart;
        setCart(cart);
        break;
      }
      case WEBSOCKET_EVENT.UPDATED_STOCK: {
        const stock = parsedData.data?.results;
        setStock(stock);
        break;
      }
      default:
        Logger.warn('Received unknown WebSocket event:', event);
    }
  }

  return {
    openWebSocket,
    handleWebSocketMessage
  };
};
