import { reactive, computed, nextTick } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import type { Cart } from '@vsf-enterprise/commercetools-types';
import { useStock } from '~/composables';
import { DEBOUNCE_TIMES } from '~/constants/debounceTimes';

const state = reactive({
  isWishlistSidebarOpen: false,
  isFilterModalOpen: false,
  isCartDrawerOpen: false,
  isExtraGuaranteeOpen: false
});

const useUiState = () => {
  const {
    loadStock
  } = useStock();

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const isSafariMobile = () => window?.navigator?.userAgent.match(/(iPod|iPhone|iPad)/) &&
  window?.navigator?.userAgent.match(/AppleWebKit/);
  const toggleWishlistSidebar = () => {
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isFilterModalOpen = computed(() => state.isFilterModalOpen);
  const toggleFilterModal = () => {
    state.isFilterModalOpen = !state.isFilterModalOpen;
  };

  const isCartDrawerOpen = computed(() => state.isCartDrawerOpen);
  const toggleCartDrawer = () => {
    state.isCartDrawerOpen = !state.isCartDrawerOpen;
  };

  const closeCartDrawer = () => {
    state.isCartDrawerOpen = false;
  };

  const isExtraGuaranteeOpen = computed(() => state.isExtraGuaranteeOpen);
  const toggleExtraGuarantee = () => {
    state.isExtraGuaranteeOpen = !state.isExtraGuaranteeOpen;
    if (!state.isExtraGuaranteeOpen) {
      if (isSafariMobile()) {
        nextTick(() => {
          setTimeout(() => {
            toggleCart();
          }, DEBOUNCE_TIMES.SAFARI_CART_DELAY);
        });
      } else {
        toggleCart();
      }
    }
  };

  const toggleCart = (cart: Maybe<Ref<Cart>> = undefined) => {
    if (cart?.value?.lineItems) {
      loadStock({ items: cart.value.lineItems });
    }
    toggleCartDrawer();
  };

  return {
    isWishlistSidebarOpen,
    isFilterModalOpen,
    isCartDrawerOpen,
    toggleWishlistSidebar,
    toggleFilterModal,
    toggleCartDrawer,
    isExtraGuaranteeOpen,
    toggleExtraGuarantee,
    toggleCart,
    closeCartDrawer
  };
};

export default useUiState;
