export const EVENT_PURCHASE = 'purchase';
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const EVENT_BEGIN_CHECKOUT_DEFAULT_EVENT_TYPE = 'interaction';
export const EVENT_PAY_NOW = 'pay_now';
export const EVENT_PURCHASE_DEFAULT_EVENT_TYPE = 'interaction';
export const EVENT_PAGE_VIEW = 'page_view';
export const EVENT_VIEW_ITEM_LIST = 'view_item_list';
export const EVENT_PRODUCT_DETAIL = 'product_detail';
export const EVENT_VIEW_ACCESSORY = 'accessory_view';
export const ADD_TO_CART_EVENT_NAME = 'add_to_cart';
export const ADD_TO_CART_EVENT_DETAIL = 'add_to_cart';
export const ADD_TO_CART_DEFAULT_EVENT_TYPE = 'interaction';
export const REMOVE_FROM_CART_EVENT_NAME = 'remove_from_cart';
export const REMOVE_FROM_CART_EVENT_DETAIL = 'remove_from_cart';
export const REMOVE_FROM_CART_DEFAULT_EVENT_TYPE = 'interaction';
export const EVENT_VIEW_HITS = 'view_hits';
export const EVENT_CLICK_HIT = 'click_hit';
export const EVENT_CONVERSION = 'conversion';
export const EVENT_VOLUME_CHANGE = 'volume_change';
export const PRIMARY_CATEGORY = 'ecommerce';
export const EVENT_CLICK_ACCESSORY = 'accessory_click';
export const SEARCH_RESULTS_EVENT_NAME = 'search_results';
export const SEARCH_RESULTS_EVENT_TYPE = 'interaction';
export const SEARCH_RESULTS_PRIMARY_CATEGORY = 'search';
export const ITEM_CATEGORY = 'item_category';
export const EVENT_PRODUCT_BOX_CLICK = 'product_box_click';
export const EVENT_PRODUCT_BOX_CLICK_DETAIL = 'click on product box';
export const BREADCRUMBS_CLICK = 'breadcrumbs_click';
export const BREADCRUMBS_CLICK_DETAIL = 'click on breadcrumbs';

export const EVENT_SOURCE = {
  HOMEPAGE: 'homepage',
  HOMEPAGE_TOP_SELLERS: 'home_top_sellers',
  CATEGORY_LISTING: 'category_listing',
  SEARCH_LISTING: 'search_listing',
  LAST_SEEN: 'last_seen',
  SIMILAR_PRODUCTS: 'similar_products',
  ACCESSORIES: 'accessories',
  CART_RECOMMENDATION: 'cart_recommendation',
  PDP: 'pdp',
  PDP_SIMILAR_PRODUCTS: 'pdp_similar_products',
  PDP_WARRANTY_SECTION: 'pdp_warranty_section',
  CART_PREVIEW: 'cart_preview',
  CART_SIDEBAR: 'cart_sidebar'
} as const;

export const EVENT_PAGE_TYPE = {
  CATEGORY: 'category',
  PDP: 'PDP',
  HOMEPAGE: 'homepage',
  SEARCH: 'search',
  CHECKOUT: 'checkout'
} as const;

export const QUANTITY_ACTION_TYPE = {
  INCREMENT: 'increment',
  DECREMENT: 'decrement',
  TEXT_INPUT: 'text input'
} as const;

export type QuantityActionType = ValueOf<typeof QUANTITY_ACTION_TYPE>;
